import request from './_config'

const path = `store`

export function getStores(tiktok_account_id, params) {
    return request({
        url: `${path}/get/${tiktok_account_id}`,
        method: 'get',
        params: {
            bc_id: params.bc_id
        }
    })
}

export function getShopsFromAtosa(tiktok_account_id, params) {
    return request({
        url: `${path}/get_from_atosa/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            belong_to_atosa: params.belong_to_atosa,
            bc_id: params.bc_id
        }
    })
}

export function getRequestAccessTiktokShop() {
    return request({
        url: `${path}`,
        method: 'get'
    })
}

export function requestAccessTiktokShop(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function deleteRequestAccessTiktokShop(tiktok_account_id, shop_code) {
    return request({
        url: `${path}/${tiktok_account_id}?shop_code=` + shop_code,
        method: 'delete'
    })
}

export function getProductsInTiktokShop(tiktok_account_id, params) {
    return request({
        url: `${path}/product/${tiktok_account_id}`,
        method: 'get',
        params
    })
}
